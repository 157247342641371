<style type="text/css">
  @import "~@/assets/css/common.css";
</style>
<script>
  import Layout from "@/views/layouts/main";
  import PageHeader from "@/components/page-header";
  import {
    getWebNoticePage,
    deleteWebNotice,
  } from "@/api/admin/notice/notice.js";

  /**
   * Dashboard component
   */
  export default {
    components: {
      Layout,
      PageHeader,
    },
    data() {
      return {
        title: "系统消息",
        items: [{
            text: "用户首页",
            href: "/admin",
          },
          {
            text: "系统消息",
            active: true,
          },
        ],
        isAdmin: "",
        pageData: {
          nowPage: 1,
          pageSize: 10,
          total: 0
        },
        noticeList: [],
        nTitle: "系统消息通知",
      };
    },
    methods: {
      handleSizeChange(val) {
        this.pageData.pageSize = val;
        this.getList();
      },
      handleCurrentChange(val) {
        this.pageData.pageNum = val;
        this.getList();
      },
      getList() {
        getWebNoticePage(this.pageData).then((res) => {
          if (res.status) {
            this.noticeList = res.data;
            this.pageData.total = res.total;
          }
        });
      },
      deleteNotice(nid, text) {
        this.$confirm(`此操作将永久删除该【${text}】的数据, 是否继续?`, "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).then(() => {
          deleteWebNotice(nid).then((res) => {
            if (res.status) {
              this.$message({
                type: "success",
                message: "删除成功",
              });
              this.getList();
            }
          });
        });
      },
    },
    mounted() {
      let path = window.location.pathname;
      let pathArr = path.split("/");
      this.isAdmin = pathArr.indexOf("admin");
      this.getList();
    },
  };
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card" style="min-height: 165px">
          <div class="card-body">
            <h4 class="card-title">{{ nTitle }}</h4>
            <div class="d-flex border-dash p-3" v-for="(v, i) in noticeList" :key="i">
              <el-badge is-dot v-if="!v.sfyd"></el-badge>
              <img src="@/assets/images/person/stLine-talk-l.png" class="sys-img mr-3" alt="" />
              <div>
                <a href="javascript:;" @click="$yzNotice().show(v.noticeId, getList)" v-if="isAdmin === 1">
                  <div class="text-dark">{{ v.noticeTitle }}</div>
                </a>
                <a href="javascript:;" @click="$yzNotice().show(v.noticeId, getList)" v-else>
                  <div class="text-dark">{{ v.noticeTitle }}</div>
                </a>
                <div class="message-bottom">
                  <span class="grey-time">{{
                    v.createTime.substring(0, 16)
                  }}</span><span class="ml-2">系统{{ v.noticeType == 1 ? "通知" : "公告" }}</span>
                  <i class="iconfont message-delete icon-riLine-delete-bin-line" style="cursor: pointer"
                    @click="deleteNotice(v.noticeId,v.noticeTitle)"></i>
                </div>
              </div>
            </div>
          </div>
          <div style="text-align: center" class="pb-4">
            <el-pagination @size-change="handleSizeChange" background @current-change="handleCurrentChange"
              :current-page.sync="pageData.pageNum" :page-size="pageData.pageSize"
              layout="total, sizes,jumper, prev, pager, next" :total="pageData.total">
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
